.appDesignContainer {
    background-color: #EFEAEA;
}
.logoImageContainer {
    display: flex;
    justify-content: center;
}
.appLogoDesign {
    width: 33%;
    padding: 0px 40px;
}
.clickCollectContainer {
    background-color: #ECEFF8;
}
.footwearContainer {
    background-color: #ECEFF8;
}
.requestsContainer {
    background-color: #CBDBCF;
}
.frappContainer {
    background-color: #ECEFF8;
}
.sevenContainer {
    background-color: #EFEAEA;
}

@media screen and (max-width: 800px) {
    .appLogoDesign {
        padding: 0px 30px;
    }
  }

@media screen and (max-width: 600px) {
    .appLogoDesign {
        padding: 0px 10px;
    }
  }


  /* CBDBCF ECEFF8  EEEEEE  ECEFF8 EFEAEA */