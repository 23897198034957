.omnichannelContainer {
    background-color: #ECEFF8;
}

.omnichannelWidth {
    width: 70%;
}

@media screen and (max-width: 800px) {
    .omnichannelWidth {
        width: 80%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .omnichannelWidth {
        width: 90%;
    }
  }