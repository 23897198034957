.parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    /* height: 100%; */
    grid-row-gap: 30%;
}

.nameLocation { grid-area: 3 / 3 / 4 / 4; }
.email { grid-area: 5 / 1 / 6 / 2; }
.github { grid-area: 5 / 3 / 6 / 4; }
.cv { grid-area: 5 / 5 / 6 / 6; }

.alignment {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 20px 20px 20px 20px;
}

.nameLocation {
    font-family: "Medium";
}

.contactText {
    font-family: "Regular";
}

@media screen and (max-width: 1390px) {
    .parent {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 12px;
        }
        
        .nameLocation { grid-area: 1 / 1 / 2 / 2; }
        .email { grid-area: 2 / 1 / 3 / 2; }
        .github { grid-area: 3 / 1 / 4 / 2; }
        .cv { grid-area: 4 / 1 / 5 / 2; }

        .alignment {
            padding: 30px;
        }
}

@media screen and (max-height: 921px) {
    .parent {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 12px;
        }
        .nameLocation { grid-area: 1 / 1 / 2 / 2; }
        .email { grid-area: 2 / 1 / 3 / 2; }
        .github { grid-area: 3 / 1 / 4 / 2; }
        .cv { grid-area: 4 / 1 / 5 / 2; }
        .alignment {
            padding: 30px;
        }
}