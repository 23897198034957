.footer {
    height: 160px;
    font-family: 'Medium';
    font-size: 22px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.text {
    padding: 30px 20px;
}

a {
    color: black;
}

@media screen and (max-width: 600px) {
    .footer {
        height: 100px;
    }
}