/* styles for grid */
.container {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "illustrations event-posters lds-logos"
    "illustrations app-designs strategic-plan"
    "omni-channel-infographic salsa-as-political-speech strategic-plan";
  background-color: #FFFFFF;
}

.illustrations { 
  grid-area: illustrations; 
  background-color: #FFFFFF;
}
.event-posters { 
  grid-area: event-posters; 
  background-color: #E3D6A5;
}
.app-designs { 
  grid-area: app-designs; 
  background-color: #CBDBCF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-logos { 
  grid-area: lds-logos; 
  background-color: #BCCBD0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.strategic-plan { 
  grid-area: strategic-plan; 
  background-color: #EDF3FB;
  display: flex;
  justify-content: center;
  align-items: center;
}
.salsa-as-political-speech { 
  grid-area: salsa-as-political-speech; 
  background-color: #ECE2DD;
  display: flex;
  justify-content: center;
  align-items: center;
}
.omni-channel-infographic { 
  grid-area: omni-channel-infographic; 
  background-color: #ECEFF8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* styles for image */
.responsive {
  width: 80%;
}
.smallerImage {
  width: 70%;
}
.padding {
  padding:  40px 0px 40px 0px;
}

/* styles for text over image */
.img__wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.img__img {
  align-self: center;
  justify-self: center;
}

.img__description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(111, 111, 112, 0.6);
  color: white;
  font-family: 'Medium';
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .1s, visibility .1s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .1s;
  transform: translateY(1em);
  text-align: center;
}

.img__wrap:hover .img__description {
  transform: translateY(0);
}

/* Styles for responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .container {
    flex: 100%;
    max-width: 100%;
  }
}
