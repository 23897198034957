.version1Container {
    background-color: #CBCED8;
}

.version2Container {
    background-color: #D8E6F5;
}

.planPilarsContainer {
    background-color: #E3D6A5;
}

.finalVersionContainer {
    background-color: #EEEEEE;
}

.strategicTallImageWidth {
    width: 50%;
}

.strategicWideImageWidth {
    width: 70%;
}

@media screen and (max-width: 800px) {
    .strategicTallImageWidth {
        width: 70%;
    }
    .strategicWideImageWidth {
        width: 70%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .strategicTallImageWidth {
        width: 80%;
    }
    .strategicWideImageWidth {
        width: 80%;
    }
  }