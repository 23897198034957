.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #DFCDC9;
}

.fixed {
  overflow: hidden;
}

img {
  transition: width 2s;
}
