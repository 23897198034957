.whiteContainer {
    background-color: white;
}
.liveSketchSingleImage {
    width: 40%;
}
.pictureSketch {
    width: 25%;
}

.procrastinationContainer {
    background-color: #EFEAEA;
}
.imageContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.procrastination {
    width: 33%;
    padding: 0px 40px;
}


.portugalContainer{
    display: flex;
}
.twoRunnersContainer {
    background-color: #FFE4E5;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.inesContainer {
    background-color: #CDABAB;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.halfSingleImage {
    width: 58%;
    align-self: center;
}

@media screen and (max-width: 800px) {
    .liveSketchSingleImage {
        width: 60%;
    }
    .pictureSketch {
        width: 38%;
    }
    .procrastination {
        width: 58%;
        padding: 40px 30px;
    }
    .portugalContainer{
        flex-wrap: wrap;
    }
    .twoRunnersContainer {
        width: 100%;
    }
    .inesContainer {
        width: 100%;
    }
  }

@media screen and (max-width: 600px) {
    .liveSketchSingleImage {
        width: 70%;
    }
    .pictureSketch {
        width: 50%;
    }
    .procrastination {
        width: 58%;
        padding: 40px 30px;
    }
  }
