* { 
	margin: 0; 
	padding: 0; 
} 

button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.background { 
	background-color: #DFCDC9;
    height: 160px;
} 

.navbar { 
	display: flex; 
    justify-content: space-between;
    align-items: flex-end;
    height: 160px;
} 

.right-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
}

.App-logo { 
    height: 90px;
    align-self: flex-end;
    padding: 20px; 
} 

.nav-list { 
    display: flex;
    padding: 15px 20px; 
} 

.nav-list li { 
	list-style: none; 
    padding: 10px;
    font-family: 'Medium';
    font-size: 22px;
} 

.nav-list li a { 
	text-decoration: none; 
	color: #000; 
} 

.nav-list li a:hover { 
	color: grey; 
} 

.selected {
    text-decoration: underline;
}

.link {
    color: #000000; 
}

.title { 
    padding: 0px 30px 20px 0px;
    font-size: 22px;
    font-family: 'Italic';
    font-weight: 200;
    color: #575757;
} 

.hamburgerMenu {
    z-index: 10;
    height: 90%;
    width: 100%;
    position: absolute;
    background-color: #DFCDC9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hamburgerItem {
    font-family: "Medium";
    font-size: 35px;
    padding: 10px;
}

.appPages {
    z-index: 0;
}

.burger1{
    transform: rotate(45deg);
}
.burger2{
    opacity: 0;
}
.burger3{
    transform: rotate(-45deg);
}

/* Styles for responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .App-logo {
        height: 70px;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .hamburger {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
        padding: 20px;
    }
    
    .burger {
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: black;
        transform-origin: 1px;
        /* transition: all 0.1s linear; */
    }
    .background {
        height: 100px;
    }
    .navbar {
        height: 100px;
    }

    .App-logo {
        height: 50px;
    }
    .right-nav {
    display: none;
    }
  }
  