.anniversaryBallContainer {
    background-color: #E3D6A5;
}

.springBallContainer {
    background-color: #E4E7ED;
}

.postersTallImageWidth {
    width: 50%;
}

.postersWideImageWidth {
    width: 60%;
}

@media screen and (max-width: 800px) {
    .postersTallImageWidth {
        width: 70%;
    }
    .postersWideImageWidth {
        width: 70%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .postersTallImageWidth {
        width: 80%;
    }
    .postersWideImageWidth {
        width: 80%;
    }
  }