.topText {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 80px 30px;
    font-size: 12px;
    font-family: 'Medium';
    text-align: start;
}

.inbetweenBottomText {
    display: flex;
    justify-content: space-between;
    padding: 50px 30px 50px 30px;
    font-size: 10px;
    font-family: 'Medium';
}

.bottomText {
    display: flex;
    justify-content: space-between;
    padding: 80px 30px 30px 30px;
    font-size: 10px;
    font-family: 'Medium';
}

.imageKeyWords {
    font-size: 16px;
    font-family: 'Regular';
    font-weight: 100;
    text-align: start;
    color: #717070;
}

.description {
    color: #717070;
    background-color: #f6f5f5;
    padding: 50px 30px 50px 30px;
    font-family: "Regular";
    font-weight: 100;
    text-align: start;
    font-size: 18px;
}

@media screen and (max-width: 800px) {
    .topText {
        font-size: 12px;
    }
    .bottomText {
        font-size: 10px;
    }
    .imageKeyWords {
        font-size: 14px;
    }
    .description {
        font-size: 14px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .topText {
        font-size: 10px;
    }
    .bottomText {
        font-size: 10px;
    }
    .imageKeyWords {
        font-size: 14px;
    }
    .description {
        font-size: 14px;
    }
  }

