body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Black";
  src: local("Inter-Black"),
    url("./fonts/Inter-Black.woff") format("woff");
}

@font-face {
  font-family: "Bold";
  src: local("Inter-Bold"),
    url("./fonts/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: "Italic";
  src: local("Inter-italic"),
    url("./fonts/Inter-italic.ttf") format("woff");
}

@font-face {
  font-family: "Medium";
  src: local("Inter-Medium"),
    url("./fonts/Inter-Medium.woff2") format("woff");
}

@font-face {
  font-family: "Regular";
  src: local("Inter-Regular"),
    url("./fonts/Inter-Regular.woff") format("woff");
}



